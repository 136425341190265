import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'

import styles from './Banner.module.scss'

const BannerPDPValueDiscount = ({
    className,
    promotionMessage
}) => {
    if (!promotionMessage) return null

    const classes = classNames(styles.bannerPDPValueDiscount, className)
    const svgClasses = classNames('u-marginLeft--xs', styles.inlineSVG)

    return (
        <div className={classes} data-selector={'pdp-banner'}>
            {promotionMessage}
            <SvgSprite spriteID={'icon-reviews-checkmark'} className={svgClasses}/>
        </div>
    )
}

BannerPDPValueDiscount.propTypes = {
    className: PropTypes.string,
    promotionMessage: PropTypes.string
}

export default BannerPDPValueDiscount
